<template>
    <el-dialog :title="target === 'memory' ? '记忆库的全量搜索' : '术语库的全量搜索'" :visible.sync="show" destroy-on-close class="none-padding-body" width="1000px" top="20px" @close="handleDialogClose">
        <div class="fulltext-search-wrapper">
            <div class="form-content">
                <el-input v-model="keyword" placeholder="输入关键词" clearable ><!-- @input="inputEvent" -->
                    <el-select v-model="searchType" slot="prepend" placeholder="请选择" style="width: 125px;">
                        <template v-for="(item, index) in searchTypeOptions">
                            <el-option :key="index" :label="item.name" :value="item.value"></el-option>
                        </template>
                    </el-select>
                    <el-button slot="append" @click="pageNumber = 1; initPage('init')">开始搜索</el-button>
                </el-input>
            </div>
            <div class="text-center fonts-12 color-999 padding-y-10" v-if="keyword && searched">查找结果{{total}}条</div>
            <div class="search-result" @scroll="handleScroll">
                <template v-for="(item, index) in list">
                    <div class="item" :key="index" @click="handleItemClick(item)">
                        <div class="fonts-15 text-weight-600" v-html="item.nameHtml"></div>
                        <div class="fonts-14 margin-t-10 color-666666 line-height-1.25" v-html="item.sourceHtml" v-if="item.types.indexOf('SOURCE') > -1"></div>
                        <div class="fonts-14 margin-t-10 color-666666 line-height-1.25" v-html="item.targetHtml"  v-if="item.types.indexOf('TARGET') > -1"></div>
                        <el-divider class="margin-y-15"></el-divider>
                        <div class="flex flex-align-center fonts-12 margin-t-10 color-666666">
                            <span class="margin-r-30" v-if="item.team">
                                <svg-icon name="ib-invite-1" class="fonts-16"></svg-icon>
                                <span class="margin-l-8">所属团队：{{item.team.teamName}}</span>
                            </span>
                            <span class="margin-r-30">
                                <svg-icon name="ib-user-o" class="fonts-16"></svg-icon>
                                <span class="margin-l-8">创建人：{{item.createBy}}</span>
                            </span>
                            <span class="margin-r-30">
                                <svg-icon name="ib-website" class="fonts-16"></svg-icon>
                                <span class="margin-l-8">语向：<language-direction :source="item.sourceLang" :target="item.targetLang" type="half" class="inline-block"></language-direction> | {{item.nums}}条</span>
                            </span>
                            <span class="margin-r-30">
                                <svg-icon name="ib-alarm" class="fonts-16"></svg-icon>
                                <span class="margin-l-8">修改时间：{{item.createTime}}</span>
                            </span>
                        </div>
                    </div>
                </template>
            </div>
            <div class="text-center fonts-12 color-999 padding-y-5" v-if="loading"><i class="el-icon-loading"></i> 加载中...</div>
            <div class="text-center fonts-12 color-999 padding-y-5" v-if="!loading && !hasNext && total > 0">已经展示全部结果</div>
        </div>
    </el-dialog>
</template>
<script>
import LanguageDirection from '@/components/LanguageDirection';
import { memoryApi, termApi} from '@/utils/api';
export default {
    components: {
        LanguageDirection,
    },
    data() {
        return {
            target: 'memory', // memory or term
            show: false,
            loading: false,
            keyword: '',
            sourceLang: '',
            targetLang: '',
            teamId: '',
            searched: false,
            list: [],
            pageNumber: 1,
            pageSize: 10,
            hasNext: true,
            total: 0,
            searchType: 'CONTENT',
            searchTypeOptions: [{value:'TITLE', name: '按标题搜索'},{value:'CONTENT', name: '按内容搜索'}],
            searchTimer: null,
        }
    },
    methods: {
        setShow(val, target, sourceLang, targetLang, teamId) {
            this.searchTypeOptions = [{value:'TITLE', name: '按标题搜索'},{value:'CONTENT', name: '按内容搜索'}];
            this.show = val;
            this.target = target;
            this.sourceLang = sourceLang;
            this.targetLang = targetLang;
            this.teamId = teamId;
        },
        inputEvent(val) {
            if(this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                this.initPage('init');
            }, 500)
        },
        initPage(type = 'init') {
            if(!this.keyword.trim()) {
                this.$message.error('请输入搜索内容');
                return;
            }
            this.searched = true;
            this.loading = true;
            let url = memoryApi.queryMemorysByFulltext;
            if(this.target === 'term') {
                url = termApi.queryTermsByFulltext;
            }
            url = `${url}?keyword=${encodeURI(this.keyword)}&type=${this.searchType}` + 
                    `&sourceLang=${this.sourceLang}&targetLang=${this.targetLang}` +
                    `&teamId=${this.teamId}&pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    let data = res.data;
                    this.total = data.total;
                    this.hasNext = data.hasNext;
                    let reg = new RegExp(this.keyword, "gi"); //不区分大小写
                    let rstList = data.records.map(item => {
                        return {
                            ...item,
                            nameHtml: '标题：' + item.name.replace(reg, char=>{return `<span class="highlight-text">${char}</span>`}),
                            sourceHtml: item.source ? '原文：' + item.source.replace(reg, char=>{return `<span class="highlight-text">${char}</span>`}) : '',
                            targetHtml: item.target ? '译文：' + item.target.replace(reg, char=>{return `<span class="highlight-text">${char}</span>`}) : '',
                        }
                    });
                    if(type === 'init') {
                        this.list = rstList;
                    }else if(type === 'append') {
                        this.list = this.list.concat(rstList);
                    }
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        handleItemClick(item) {
            let query = {id: item.libId};
            if(item.detailId) {
                query.dId = item.detailId;
            }
            if(this.target === 'memory') {
                window.open(this.$router.resolve({path: '/asset/memory/detail', query}).href, '_blank');
            }else if(this.target === 'term') {
                window.open(this.$router.resolve({path: '/asset/term/detail', query}).href, '_blank');
            }
        },
        handleDialogClose() {
            this.searched = false;
            this.keyword = '';
            this.pageNumber = 1;
            this.hasNext = true;
            this.list = [];
            clearTimeout(this.searchTimer);
            this.searchTimer = null;
        },
        handleScroll(event) {
            const element = event.target;
            const distanceFromBottom = element.scrollHeight - element.clientHeight - element.scrollTop;
            if(distanceFromBottom < 10) {
                // 调用一个方法来加载更多内容
                if(this.hasNext && !this.loading){
                    this.pageNumber = this.pageNumber + 1;
                    this.initPage('append');
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.fulltext-search-wrapper {
    padding: 20px 0 10px 0;
    .form-content {
        padding: 0 30px;
        ::v-deep .el-input {
            .el-input__inner {
                border-color: #DCDFE6;
                border-right-color: transparent;
                border-left-color: transparent;
            }
            .el-input-group__prepend {
                border-right: 1px solid #DCDFE6;
                background-color: #fff;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                .el-input__inner {
                    padding: 0 20px;
                    color: #000;
                }
            }
            .el-input-group__append {
                background-color: #fff;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                .el-button {
                    border-radius: 20px;
                    background-color: #000;
                    color: #fff;
                }
            }
        }
    }
    .search-result {
        max-height: calc(100vh - 270px);
        overflow-y: auto;
        padding: 10px 30px;
        .item {
            background-color: #f5f5f5;
            border-radius: 10px;
            padding: 20px;
            cursor: pointer;
            margin-bottom: 10px;
            ::v-deep .highlight-text {
                color: #00B670;
            }
            &:hover {
                background-color: #E9F8F2;
            }
        }
    }
}
</style>
