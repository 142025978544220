<template>
    <div class="asset-tabs-component">
        <template v-for="tab in tabs">
            <div :class="['item',active === tab.key?'active':'']" :key="tab.key" @click="gotoPage(tab)">
                {{tab.name}}
            </div>
        </template>
    </div>
</template>
<script>
import AssetTerm from '@/components/icon/AssetTerm';
import AssetMemory from '@/components/icon/AssetMemory';
export default {
    name:'AssetTabs',
    components:{ AssetMemory, AssetTerm },
    props:{
        active:{
            type:String,
            default:'memory',
        }
    },
    data(){
        return {
            tabs:[
                {name:'记忆库',key:'memory',path:'/asset/memory/index', permission: 'memory'},
                {name:'术语库',key:'term',path:'/asset/term/index', permission: 'memory:termStore1112121'},
            ]
        }
    },
    methods:{
        checkPermission(val){
            return this.$checkPermission(val);
        },
        gotoPage(tab){
            if(this.active !== tab.key){
                this.$router.push(tab.path);
            }
        }
    },
    mounted(){

    }
}
</script>
<style lang="scss" scoped>
.asset-tabs-component{
    border-bottom: 1px solid #dfdfdf;
    padding: 10px 0;
    display: flex;
    > .item{
        color: #2C2C2E;
        font-size: 15px;
        box-sizing: border-box;
        padding: 10px 25px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        &.active{
            color: #00D959;
            background-color: #2C2C2E; 
        }
    }
}
</style>
